import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

export function Login() {
    const [values, setValues] = useState({ email: '', password: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { setUserInfo } = useContext(UserContext);
    const [error, setError] = useState('');

    axios.defaults.withCredentials = true;

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleRememberMeChange = (event) => {
        setRememberMe(event.target.checked);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const token = localStorage.getItem('token-techcomp') || sessionStorage.getItem('token-techcomp');
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };
    
        axios.post('https://web-production-a6f8.up.railway.app/login', values, config)
            .then(res => {
                if (res.data.Status === "Exito") {
                    const token = res.data.token;
    
                    // Almacenar el token en localStorage o sessionStorage
                    if (rememberMe) {
                        localStorage.setItem('token-techcomp', token);
                    } else {
                        sessionStorage.setItem('token-techcomp', token);
                    }
    
                    // Configurar datos del usuario
                    const userType = res.data.type;
                    const userName = res.data.name;
                    setUserInfo({ name: userName, type: userType });
    
                    // Redirigir según el tipo de usuario
                    const from = location.state?.from?.pathname || (userType === 'admin' ? '/' : '/report');
                    navigate(from);
                } else {
                    setError(res.data.Error || "Login error");
                }
            })
            .catch(err => {
                setError("Login error");
            });
    };


    return (
        <div>
            <div className="position-relative">
                <div className="authentication-wrapper authentication-basic container-p-y p-4 p-sm-0">
                    <div className="authentication-inner py-6">
                        <div className="card p-md-7 p-1">
                            <div className="app-brand justify-content-center mt-5">
                                <a href="/" className="app-brand-link gap-2">
                                    <img src="../assets/img/LogoTechomp.png" alt="techcomp" style={{width:'100%'}} />
                                </a>
                            </div>

                            <div className="card-body mt-1">
                                <h4 className="mb-1">Welcome!</h4>
                                <p className="mb-5">Please, log in with your account.</p>

                                <form onSubmit={handleSubmit} className="mb-5">
                                    <div className="form-floating form-floating-outline mb-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            name="email-username"
                                            placeholder="Enter your email or username."
                                            autoFocus
                                            onChange={e => setValues({ ...values, email: e.target.value })} />
                                        <label htmlFor="email">Email or username</label>
                                    </div>
                                    <div className="mb-5">
                                        <div className="form-password-toggle">
                                            <div className="input-group input-group-merge">
                                                <div className="form-floating form-floating-outline">
                                                    <input
                                                        type={showPassword ? "text" : "password"}
                                                        id="password"
                                                        className="form-control"
                                                        name="password"
                                                        placeholder="••••••••••••"
                                                        aria-describedby="password"
                                                        onChange={e => setValues({ ...values, password: e.target.value })} />
                                                    <label htmlFor="password">Password</label>
                                                </div>
                                                <span
                                                    className="input-group-text cursor-pointer"
                                                    onClick={handlePasswordVisibility}
                                                >
                                                    <i className={showPassword ? "ri-eye-line" : "ri-eye-off-line"}></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {error && <p style={{color:'red'}}>{error}</p>}
                                    <div className="mb-5 d-flex justify-content-between mt-5">
                                        <div className="form-check mt-2">
                                            <input 
                                                className="form-check-input" 
                                                type="checkbox" 
                                                id="remember-me" 
                                                checked={rememberMe}
                                                onChange={handleRememberMeChange} 
                                            />
                                            <label className="form-check-label" htmlFor="remember-me"> Remember me </label>
                                        </div>
                                    </div>
                                    <div className="mb-5">
                                        <button className="btn btn-primary d-grid w-100" type="submit">Enter</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export function Register() {

    const [values, setValues] = useState({
        name: '',
        email: '',
        password: '',
        type: 'user'
    });
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post('https://web-production-a6f8.up.railway.app/register', values)
        .then(res => {
            if (res.data.Status === "Exito") {
            navigate('/login');
            } else {
            setError(res.data.Error || "Error al registrar");
            }
        })
        .catch(err => {
            console.log(err);
            setError("Error al registrar");
        });
    };

    return(
        <div>
            <div className="position-relative">
                <div className="authentication-wrapper authentication-basic container-p-y p-4 p-sm-0">
                    <div className="authentication-inner py-6">
                    <div className="card p-md-7 p-1">
                        <div className="app-brand justify-content-center mt-5">
                        <a href="index.html" className="app-brand-link gap-2">
                            <img src="" alt="techcomp" />
                        </a>
                        </div>

                        <div className="card-body mt-1">
                        <h4 className="mb-1">¡Registro a Techcomp! ❄️</h4>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <form onSubmit={handleSubmit} className="mb-5">
                            <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                className="form-control"
                                id="username"
                                name="username"
                                placeholder="Introduce nombre de usuario."
                                autofocus 
                                onChange={e => setValues({...values, name: e.target.value})}/>
                            <label htmlFor="username">nombre de usuario</label>
                            </div>
                            <div className="form-floating form-floating-outline mb-5">
                            <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="email-username"
                                placeholder="Introduce tu email."
                                autofocus 
                                onChange={e => setValues({...values, email: e.target.value})}/>
                            <label htmlFor="email">Email </label>
                            </div>
                            <div className="mb-5">
                            <div className="form-password-toggle">
                                <div className="input-group input-group-merge">
                                <div className="form-floating form-floating-outline">
                                    <input
                                    type="password"
                                    id="password"
                                    className="form-control"
                                    name="password"
                                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                    aria-describedby="password" 
                                    onChange={e => setValues({...values, password: e.target.value})}/>
                                    <label htmlFor="password">Password</label>
                                </div>
                                <span className="input-group-text cursor-pointer"><i className="ri-eye-off-line"></i></span>
                                </div>
                            </div>
                            </div>
                            <div className="form-floating form-floating-outline mb-5">
                                <select
                                    className="form-control"
                                    id="user-type"
                                    value={values.type}
                                    onChange={e => setValues({...values, type: e.target.value})}>
                                    <option value="user">User</option>
                                    <option value="admin">Admin</option>
                                </select>
                                <label htmlFor="user-type">Tipo de Usuario</label>
                            </div>
                            <div className="mb-5 d-flex justify-content-between mt-5">
                            <div className="form-check mt-2">
                                <Link to="/login">Ir a Login</Link>
                            </div>
                            </div>
                            <div className="mb-5">
                            <button className="btn btn-primary d-grid w-100" type="submit">Registar</button>
                            </div>
                        </form>
                        </div>
                    </div>
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login