// GeneralSteps.js
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Webcam from 'react-webcam';
import axios from 'axios';
import { getDynamicSteps, modelChannelMap } from '../helpers/dynamicStepsHelper';

const GeneralSteps = ({ sku, userInfo, onSubmit }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({
        serialNumber: '',
        brand: '',
        model: '',
        image_name: '',
        user_id: userInfo?.id || null,
        samplers: [],
        serviceDate: new Date().toISOString().split('T')[0],
        serviceType: "", // Inicializa como cadena vacía
        generalDescription: "",
        engineerName: '',
    });

    const [lc6000FormData, setLc6000FormData] = useState({
        serialNumber: "",
        engineerName: '',
        model: "LC6000",
        brand: "Scion Instruments",
        serviceDate: new Date().toISOString().split("T")[0],
        serviceType: "",
        generalDescription: "",
        user_id: userInfo?.id || null,
        image_name: '',
        Organizer: "",
        Pump: "",
        AutoSampler: "",
        ColumnOven: "",
        Detectors: [],
    });
    
    const [errors, setErrors] = useState({});
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [showWebcam, setShowWebcam] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const webcamRef = React.useRef(null);

    const brandModels = {
        Varian: ["430", "450", "3800", "3900"],
        Bruker: ["430", "436", "450", "456"],
        "Scion Instruments": ["436", "456", "8300", "8500", "LC6000"],
    };

    useEffect(() => {
        console.log("Preview actualizado:", preview);
    }, [preview]);
    

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        // Verifica si el modelo es LC6000
        if (formData.model === "LC6000") {
            setLc6000FormData((prev) => {
                const keys = name.split(".");
                if (keys.length === 2) {
                    return {
                        ...prev,
                        [keys[0]]: {
                            ...prev[keys[0]],
                            [keys[1]]: value,
                        },
                    };
                }
                return { ...prev, [name]: value };
            });
        } else {
            setFormData((prev) => {
                const keys = name.split(".");
                if (keys.length === 2) {
                    return {
                        ...prev,
                        [keys[0]]: {
                            ...prev[keys[0]],
                            [keys[1]]: value,
                        },
                    };
                }
                return { ...prev, [name]: value };
            });
        }
    };    

    const validateStep = () => {
        const stepErrors = {};

        if (currentStep === 1 && !formData.serialNumber) {
            stepErrors.serialNumber = 'El número de serie es obligatorio.';
        }
        if (currentStep === 2) {
            if (!formData.brand) {
                stepErrors.brand = 'La marca es obligatoria.';
            }
            if (!formData.model) {
                stepErrors.model = 'El modelo es obligatorio.';
            }
        }

        setErrors(stepErrors);
        return Object.keys(stepErrors).length === 0;
    };

    const handleRemoveImage = () => {
        setFile(null);
        setPreview(null);
    };

    const capturePhoto = () => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            if (imageSrc) {
                // Convertir la captura en un archivo válido con nombre ajustado
                fetch(imageSrc)
                    .then((res) => res.blob())
                    .then((blob) => {
                        const newFileName = `${sku}-captured_image.jpg`; // Renombrar con SKU dinámico
                        const file = new File([blob], newFileName, { type: 'image/jpeg' });
                        setFile(file); // Actualiza el archivo
                        setPreview(URL.createObjectURL(file)); // Crea una previsualización válida
                        setShowWebcam(false);
                        console.log("Imagen capturada con nombre:", newFileName);
                    })
                    .catch(() => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'No se pudo procesar la foto. Intente de nuevo.',
                        });
                    });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudo capturar la foto. Intente de nuevo.',
                });
            }
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const allowedExtensions = ['image/jpeg', 'image/png', 'image/jpg']; // Extensiones permitidas
    
        if (!selectedFile) return;
    
        // Verificar el tipo de archivo
        if (!allowedExtensions.includes(selectedFile.type)) {
            Swal.fire({
                icon: 'error',
                title: 'Archivo no permitido',
                text: 'Solo se permiten archivos .jpg, .jpeg y .png',
            });
            setFile(null);
            setPreview(null);
            return;
        }
    
        // Renombrar el archivo
        const newFileName = `${selectedFile.name}`;
        const renamedFile = new File([selectedFile], newFileName, { type: selectedFile.type });
    
        // Convertir el archivo a URL para previsualización
        setFile(renamedFile);
        setPreview(URL.createObjectURL(renamedFile));
        console.log("Archivo seleccionado con nombre:", newFileName);
    };

    const handleImageUpload = async () => {
        if (!file) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Por favor selecciona una imagen antes de subir.',
            });
            return;
        }
    
        setIsUploading(true);
    
        const formDataData = new FormData();
        formDataData.append('file', file);
        formDataData.append('userId', userInfo?.id);
    
        try {
            const response = await axios.post(
                `https://web-production-dd62a.up.railway.app/report/upload-temp/${sku}`,
                formDataData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
    
            if (response.data.Status === 'Exito') {
                // Obtener el nombre final del archivo desde la respuesta
                const uploadedFileName = response.data.filename; // Asegúrate de usar la clave correcta
    
                // Actualizar el estado con el nombre correcto
                setFormData((prev) => ({
                    ...prev,
                    image_name: uploadedFileName,
                }));
    
                Swal.fire({
                    icon: 'success',
                    title: 'Photo Uploaded',
                    timer: 2000,
                    showConfirmButton: false,
                }).then(() => setCurrentStep((prev) => prev + 1));
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.Error || 'Error al subir la imagen.',
                });
            }
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un error al subir la imagen.',
            });
        } finally {
            setIsUploading(false);
        }
    };

    const handleFinish = async () => {
        const form = document.querySelector("form");
        if (!form.checkValidity()) {
            form.reportValidity();
            return;
        }
    
        // Validar que la imagen se haya subido
        if (!preview) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Por favor suba una imagen antes de enviar.",
            });
            return;
        }        
    
        // Construcción del JSON final
        const concatenatedServiceType = Array.isArray(formData.serviceType)
        ? formData.serviceType.join(", ")
        : formData.serviceType || "";

    
        let finalJson = {
            serialNumber: formData.serialNumber,
            model: formData.model,
            brand: formData.brand,
            serviceDate: formData.serviceDate,
            serviceType: concatenatedServiceType,
            generalDescription: formData.generalDescription,
            user_id: formData.user_id || userInfo?.id || 18,
            image_name: formData.image_name || (preview ? preview.split("/").pop() : ""),
            engineerName: formData.engineerName, 
        };
    
        // Verificar si el modelo es LC6000
        if (formData.model === "LC6000") {
            const concatenatedServiceType = Array.isArray(lc6000FormData.serviceType)
                ? lc6000FormData.serviceType.join(", ")
                : lc6000FormData.serviceType || "";
        
            // Agregar módulos individuales en el formato esperado por el backend
            finalJson = {
                ...finalJson,
                serviceType: concatenatedServiceType,
                generalDescription: lc6000FormData.generalDescription || "",
                Organizer: lc6000FormData.Organizer || "NONE",
                Organizer_serial_number: lc6000FormData["Organizer_serial"] || "",
                Pump: lc6000FormData.Pump || "NONE",
                Pump_serial_number: lc6000FormData["Pump_serial"] || "",
                AutoSampler: lc6000FormData.AutoSampler || "NONE",
                AutoSampler_serial_number: lc6000FormData["AutoSampler_serial"] || "",
                ColumnOven: lc6000FormData.ColumnOven || "NONE",
                ColumnOven_serial_number: lc6000FormData["ColumnOven_serial"] || "",
                Detectors: lc6000FormData.Detectors?.map((detector) => ({
                    name: detector,
                    serial_number: lc6000FormData[`Detectors_serial_${detector}`] || "",
                })) || [],
            };
        } else {
                // Agregar canales y sampler para otros modelos
                const channels = modelChannelMap[formData.model]?.map((channel) => ({
                    canal: channel,
                    inyector: formData[`injector_${channel}`] || "",
                    detector: formData[`detector_${channel}`] || "",
                    serialNumber: formData[`serial_${channel}`] || "",
                    descripcion: formData[`description_${channel}`] || "",
                    columnPart: formData[`columnPart_${channel}`] || "",
                    columnDescription: formData[`columnDescription_${channel}`] || "",
                }));
        
                finalJson = {
                    ...finalJson,
                    channels,
                    samplers: [
                        formData.sampler1,
                        formData.sampler2,
                    ],
                };
            }
    
        console.log("JSON Enviado:", JSON.stringify(finalJson, null, 2));
    
        try {
            const response = await axios.post(
                `https://web-production-dd62a.up.railway.app/report/complete/${sku}`,
                finalJson,
                { headers: { "Content-Type": "application/json" } }
            );
    
            if (response.data.Status === "Exito") {
                Swal.fire({
                    icon: "success",
                    title: "Information sent",
                    text: "The report has been sent successfully.",
                    timer: 2000,
                    showConfirmButton: false,
                }).then(() => {
                    window.location.reload();
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Error al guardar el reporte.",
                });
            }
        } catch (err) {
            console.error("Error al enviar el formulario:", err);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Hubo un error al guardar el reporte.",
            });
        }
    };      

    // Obtener pasos dinámicos
    const dynamicSteps = getDynamicSteps({
        formData,
        lc6000FormData,
        setLc6000FormData,
        model: formData.model || lc6000FormData.model,
        handleChange,
        currentChannel: 0, // Puedes cambiar esto para manejar múltiples canales
        handleSubmit: onSubmit,
        handleFinish,
        preview,
    });

    const steps = [
        // Importar Imagen
        {
            title: 'Upload Image',
            content: (
                <div>
                    <form className="upload-form position-relative">
                        <div className="upload-container">
                            {showWebcam ? (
                                // Webcam para tomar foto
                                <div className="webcam-container position-relative" style={{ width: '100%', height: '400px' }}>
                                    <Webcam
                                        audio={false}
                                        ref={webcamRef}
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={{
                                            facingMode: "environment", // Selecciona la cámara trasera
                                        }}
                                        className="webcam-preview"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </div>
                            ) : (
                                <>
                                    {/* Vista previa de la imagen seleccionada */}
                                    {preview ? (
                                        <div className="upload-preview-container position-relative">
                                            <img
                                                src={preview}
                                                alt="Vista previa"
                                                className="upload-preview"
                                                style={{
                                                    width: '100%',
                                                    height: '400px',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                            <button
                                                type="button"
                                                className="remove-preview"
                                                onClick={() => {
                                                    setFile(null);
                                                    setPreview(null);
                                                }}
                                            >
                                                &times;
                                            </button>
                                        </div>
                                    ) : (
                                        // Input para seleccionar archivo
                                        <label htmlFor="fileUpload" className="upload-label">
                                            <div className="upload-icon text-center">
                                                <i
                                                    className="ri-file-upload-line"
                                                    style={{ fontSize: '50px' }}
                                                ></i>
                                            </div>
                                            <span className="upload-text">
                                                {file ? file.name : 'Upload Photo'}
                                            </span>
                                            <input
                                                type="file"
                                                id="fileUpload"
                                                className="upload-input"
                                                onChange={handleFileChange} // Maneja la selección del archivo
                                                accept="image/jpeg, image/png, image/jpg" // Restringir tipos
                                            />
                                        </label>
                                    )}
                                </>
                            )}
                        </div>
        
                        {/* Botones de subir foto y abrir cámara */}
                        <div className="upload-buttons d-flex justify-content-center mt-3 gap-2">
                            <button
                                type="button"
                                className="btn btn-secondary waves-effect waves-light"
                                onClick={() => {
                                    setShowWebcam(!showWebcam);
                                    if (showWebcam) {
                                        setPreview(null); // Limpia la previsualización si se cierra la cámara
                                    }
                                }}
                            >
                                {showWebcam ? 'Close Camera' : 'Open Camera'}
                            </button>
        
                            {isUploading ? (
                                <button className="btn btn-primary" type="button" disabled>
                                    Loading...
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={showWebcam ? capturePhoto : handleImageUpload}
                                >
                                    {showWebcam ? 'Take a Photo' : 'Upload Photo'}
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            ),
        },       
        // Ingresar numero de serie
        {
            title: 'Serial Number',
            content: (
                <div>
                    <div className="form-floating">
                        <input
                            type="text"
                            name="serialNumber"
                            className="form-control"
                            value={formData.serialNumber}
                            onChange={handleChange}
                            placeholder="Enter Serial Number"
                            required
                        />
                        <label htmlFor="serialNumber">Serial Number</label>
                        {errors.serialNumber && (
                            <small className="text-danger">{errors.serialNumber}</small>
                        )}
                    </div>
                    <div className="form-floating mt-3">
                        <input
                            type="text"
                            name="engineerName"
                            className="form-control"
                            value={formData.engineerName}
                            onChange={handleChange}
                            placeholder="Enter your name"
                            required
                        />
                        <label htmlFor="engineerName">Service engineer</label>
                        {errors.engineerName && (
                            <small className="text-danger">{errors.engineerName}</small>
                        )}
                    </div>
                </div>
            ),
        },
        // Ingresar Marca y modelo
        {
        title: 'Brand & Model',
        content: (
            <div>
                <div className="form-floating mb-3">
                    <select
                        name="brand"
                        className="form-select"
                        value={formData.brand}
                        onChange={(e) => {
                            handleChange(e);
                            setFormData((prev) => ({ ...prev, model: '' }));
                        }}
                        required
                    >
                        <option value="">Select a Manufacturer</option>
                        {Object.keys(brandModels).map((brand) => (
                            <option key={brand} value={brand}>
                                {brand}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="brand">Brand</label>
                    {errors.brand && (
                        <small className="text-danger">{errors.brand}</small>
                    )}
                </div>
                <div className="form-floating">
                    <select
                        name="model"
                        className="form-select"
                        value={formData.model}
                        onChange={handleChange}
                        disabled={!formData.brand}
                        required
                    >
                        <option value="">Select a Model</option>
                        {formData.brand &&
                            brandModels[formData.brand].map((model) => (
                                <option key={model} value={model}>
                                    {model}
                                </option>
                            ))}
                    </select>
                    <label htmlFor="model">Model</label>
                    {errors.model && (
                        <small className="text-danger">{errors.model}</small>
                    )}
                </div>
            </div>
        ),
    },
    // Pasos dinámicos dependiendo del modelo
    ...dynamicSteps,
    ];

    const handleNext = (e) => {
        e.preventDefault();
    
        const form = e.target.closest("form"); // Encuentra el formulario actual
        if (form.checkValidity()) {
            setCurrentStep((prev) => prev + 1); // Avanzar si todos los campos son válidos
        } else {
            form.reportValidity(); // Muestra los errores de validación nativos del navegador
        }
    };
    
    
    const handlePrev = () => {
        setCurrentStep((prev) => Math.max(prev - 1, 0));
    };
    
    

    return (
        <div>
            <form onSubmit={(e) => e.preventDefault()}>
                <h3>{steps[currentStep].title}</h3>
                <div>{steps[currentStep].content}</div>

                <div className="d-flex justify-content-between mt-4">
                    {currentStep > 0 && (
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={handlePrev}
                        >
                            {currentStep === steps.length - 1 ? "Edit options" : "Previous"}
                        </button>
                    )}
                    <div>
                        {currentStep > 0 && currentStep < steps.length - 1 && (
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleNext}
                            >
                                Next
                            </button>
                        )}
                        {currentStep === steps.length - 1 && (
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={() => handleFinish(formData)}
                            >
                                Confirm & Send
                            </button>
                        )}
                    </div>
                </div>

            </form>
        </div>
    );
    
    
};

export default GeneralSteps;
