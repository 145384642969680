// dynamicStepsHelper.js
const lc6000Modules = [
    {
        module: "Organizer",
        options: [
            "SCION 6510 Organizer / Solvent cabinet with Power Supply Module and Interface Control Board",
        ],
    },
    {
        module: "Pump",
        options: [
            "SCION 6100 Pump (60MPa), With manual purge valve",
            "Quaternary Pump Set: 6100 Pump, Low Pressure Gradient Unit and 6-channel degasser",
        ],
    },
    {
        module: "AutoSampler",
        options: [
            "SCION 6210 Autosampler",
            "SCION 6220 AS with Thermostat (220V)",
            "Rheodyne® Manual Injector Rheodyne, 7725i - Includes Mounting Bracket",
        ],
    },
    {
        module: "ColumnOven",
        options: [
            "SCION 6310 Column Oven w. peltier - 220V",
            "SCION 6310 Column Oven w. peltier - 110V",
            "SCION 6320 Vertical Column Oven - 220V",
            "SCION 6320 Vertical Column Oven - 110V",
            "Column Switching Valve Kit",
            "NONE",
        ],
    },
    {
        module: "Detectors",
        options: [
            "SCION 6410 UV Detector",
            "SCION 6430 Diode Array Detector",
            "SCION 6440 FL Detector",
            "SCION 6450 RI Detector",
        ],
        multipleSelection: true, // Detectors allow multiple selection
    },
];    

export const modelChannelMap = {
    "3900": ["Front", "Rear"],
    "430": ["Front", "Rear"],
    "3800": ["Front", "Mid", "Rear"],
    "450": ["Front", "Mid", "Rear"],
    "436": ["Front", "Rear"],
    "8300": ["Front", "Rear"],
    "456": ["Front", "Mid", "Rear"],
    "8500": ["Front", "Mid", "Rear"],
};

export const getDynamicSteps = ({ formData, lc6000FormData, setLc6000FormData, model, handleChange, currentChannel, handleSubmit, handleFinish, preview }) => {
    const steps = [];

    const channelOptions = {
        injectors: ["S/SL", "PTV", "COC", "PWA", "GSV", "LSV", "NONE"],
        detectors: ["FID", "TCD", "PFPD", "NPD", "ECD", "SCD", "PDHID", "SQ", "TQ", "NONE"],
        requiresSerialAndDescription: ["SQ", "TQ", "SCD"],
    };

    if (model === "LC6000") {
        // Paso 4: Configuración de módulos
        steps.push({
            title: "Configuration of Modules",
            content: (
                <div>
                    {lc6000Modules.map((module) => (
                        <div key={module.module} className="form-group mb-4">
                            <h5>{module.module}</h5>
                            {module.multipleSelection ? (
                                // Selección múltiple (Checkbox)
                                <div>
                                    {module.options.map((option) => (
                                        <div key={option} className="form-check mb-3">
                                            <input
                                                type="checkbox"
                                                id={`${module.module}_${option}`}
                                                name={`${module.module}`}
                                                value={option}
                                                className="form-check-input"
                                                checked={lc6000FormData[module.module]?.includes(option) || false}
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    const value = e.target.value;
                                                    const existingValues = lc6000FormData[module.module] || [];

                                                    // Actualizar la lista de opciones seleccionadas
                                                    const updatedValues = isChecked
                                                        ? [...existingValues, value]
                                                        : existingValues.filter((v) => v !== value);

                                                    handleChange({
                                                        target: { name: module.module, value: updatedValues },
                                                    });

                                                    // Manejar el campo de número de serie para la opción seleccionada
                                                    setLc6000FormData((prev) => ({
                                                        ...prev,
                                                        [`${module.module}_serial_${value}`]: isChecked
                                                            ? prev[`${module.module}_serial_${value}`] || ""
                                                            : undefined, // Eliminar si se deselecciona
                                                    }));
                                                }}
                                            />
                                            <label htmlFor={`${module.module}_${option}`} className="form-check-label">
                                                {option}
                                            </label>

                                            {/* Mostrar input de número de serie si está seleccionado */}
                                            {lc6000FormData[module.module]?.includes(option) && (
                                                <input
                                                    type="text"
                                                    className="form-control mt-2"
                                                    placeholder={`Enter Serial Number for ${option}`}
                                                    value={lc6000FormData[`${module.module}_serial_${option}`] || ""}
                                                    onChange={(e) => {
                                                        setLc6000FormData((prev) => ({
                                                            ...prev,
                                                            [`${module.module}_serial_${option}`]: e.target.value,
                                                        }));
                                                    }}
                                                    required
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                // Selección única (Dropdown)
                                <div className="form-floating form-floating-outline">
                                    <select
                                        id={module.module}
                                        name={module.module}
                                        className="form-select"
                                        required
                                        value={lc6000FormData[module.module] || ""}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            handleChange({
                                                target: { name: module.module, value },
                                            });

                                            // Manejar el campo de número de serie para selección única
                                            setLc6000FormData((prev) => ({
                                                ...prev,
                                                [`${module.module}_serial`]: value ? prev[`${module.module}_serial`] || "" : undefined,
                                            }));
                                        }}
                                    >
                                        <option value="">Select an Option</option>
                                        {module.options.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor={module.module}>Select {module.module}</label>

                                    {/* Mostrar input de número de serie si se selecciona una opción */}
                                    {lc6000FormData[module.module] && (
                                        <input
                                            type="text"
                                            className="form-control mt-2"
                                            placeholder={`Enter Serial Number for ${lc6000FormData[module.module]}`}
                                            value={lc6000FormData[`${module.module}_serial`] || ""}
                                            onChange={(e) => {
                                                setLc6000FormData((prev) => ({
                                                    ...prev,
                                                    [`${module.module}_serial`]: e.target.value,
                                                }));
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            ),
        });
    
        // Paso 5: Información Adicional
        steps.push({
            title: "Additional Info",
            content: (
                <div>
                    {/* Fecha del servicio */}
                    <div className="form-floating form-floating-outline mb-4">
                        <input
                            type="date"
                            id="serviceDate"
                            name="serviceDate"
                            className="form-control"
                            value={lc6000FormData.serviceDate || new Date().toISOString().split("T")[0]} // Fecha actual
                            disabled
                            required
                        />
                        <label htmlFor="serviceDate">Day of Service</label>
                    </div>

                    {/* Tipo de Servicio */}
                    <div className="form-group mb-4">
                        <label className="mb-2">Type of Service:</label>
                        <div>
                            {[
                                "Install",
                                "PM – Preventive Maintenance",
                                "CM - Corrective Maintenance",
                                "IQ – Installation Qualification",
                                "OQ – Operational Qualification",
                            ].map((type) => (
                                <div key={type} className="form-check">
                                    <input
                                        type="checkbox"
                                        id={`serviceType_${type}`}
                                        name="serviceType"
                                        value={type}
                                        className="form-check-input"
                                        checked={
                                            lc6000FormData.serviceType?.split(", ").includes(type) || false
                                        }
                                        onChange={(e) => {
                                            const { checked, value } = e.target;
                                            const existingValues =
                                                lc6000FormData.serviceType?.split(", ").filter(Boolean) || [];

                                            const updatedValues = checked
                                                ? [...existingValues, value]
                                                : existingValues.filter((v) => v !== value);

                                            handleChange({
                                                target: { name: "serviceType", value: updatedValues.join(", ") },
                                            });
                                        }}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor={`serviceType_${type}`}
                                    >
                                        {type}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Descripción General */}
                    <div className="form-floating form-floating-outline">
                        <textarea
                            id="generalDescription"
                            name="generalDescription"
                            className="form-control h-px-120"
                            value={lc6000FormData.generalDescription || ""}
                            onChange={(e) =>
                                handleChange({
                                    target: { name: "generalDescription", value: e.target.value },
                                })
                            }
                            
                        />
                        <label htmlFor="generalDescription">Overall Description</label>
                    </div>
                </div>
            ),
        });

        // Paso 6: Confirmación de Información Adicional (LC6000)
        steps.push({
            title: "Setup Overview",
            content: (
                <div className="summary-content">
                    {/* Imagen */}
                    <div className="h-100 text-center">
                        {preview ? (
                            <img
                                src={preview}
                                alt="Vista previa"
                                className="card-img-top mb-3"
                                style={{ maxHeight: "200px", objectFit: "contain" }}
                            />
                        ) : (
                            <p>No image uploaded.</p>
                        )}
                    </div>


                    {/* Botones */}
                    <div className="d-flex justify-content-center mb-4">
                        <button
                            style={{ textTransform: "none" }}
                            className="btn btn-primary"
                            onClick={handleFinish}
                        >
                            Confirm & Send
                        </button>
                    </div>

                    {/* Información General */}
                    <div className="card-body">
                        <h5 className="card-title mb-3">General Information</h5>
                        <div className="row gx-3">
                            <div className="col-md-4">
                                <p className="card-text mb-1">
                                    <strong>S/N:</strong> {lc6000FormData.serialNumber || "Unspecified"}
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p className="card-text mb-1">
                                    <strong>Brand:</strong> {lc6000FormData.brand || "Unspecified"}
                                </p>
                            </div>
                            <div className="col-md-4">
                                <p className="card-text mb-1">
                                    <strong>Model:</strong> {lc6000FormData.model || "Unspecified"}
                                </p>
                            </div>
                        </div>
                        <div className="row gx-3">
                            <div className="col-md-6">
                                <p className="card-text mb-1">
                                    <strong>Day of service:</strong>{" "}
                                    {lc6000FormData.serviceDate || "Unspecified"}
                                </p>
                            </div>
                            <div className="col-md-6">
                                <p className="card-text mb-1">
                                    <strong>Type of Service:</strong>{" "}
                                    {lc6000FormData.serviceType || "Unspecified"}
                                </p>
                            </div>
                        </div>
                        <p className="card-text mt-2">
                            <strong>Overall Description:</strong>{" "}
                            {lc6000FormData.generalDescription || "Unspecified"}
                        </p>
                    </div>

                    {/* Módulos LC6000 */}
                    <div className="card-body">
                        <h5 className="card-title mb-3">Modules</h5>
                        {[
                            { label: "Organizer", value: lc6000FormData.Organizer, serial: lc6000FormData.Organizer_serial },
                            { label: "Pump", value: lc6000FormData.Pump, serial: lc6000FormData.Pump_serial },
                            { label: "AutoSampler", value: lc6000FormData.AutoSampler, serial: lc6000FormData.AutoSampler_serial },
                            { label: "ColumnOven", value: lc6000FormData.ColumnOven, serial: lc6000FormData.ColumnOven_serial },
                        ].map((module, index) => (
                            <p key={index} className="mb-2">
                                <strong>{module.label}:</strong> {module.value || "Unspecified"}
                                {module.serial && (
                                    <span><br/>
                                        {" "}
                                        <strong>Serial Number:</strong> {module.serial}
                                    </span>
                                )}
                            </p>
                        ))}
                        {lc6000FormData.Detectors?.length > 0 && (
                            <div className="mt-3">
                                <strong>Detectors:</strong>
                                <ul>
                                    {lc6000FormData.Detectors.map((detector, index) => (
                                        <li key={index}>
                                            <strong>{detector}:</strong>{" "}<br/>
                                            {lc6000FormData[`Detectors_serial_${detector}`]
                                                ? `Serial Number: ${lc6000FormData[`Detectors_serial_${detector}`]}`
                                                : "No Serial Number"}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            ),
        });


    } else if (modelChannelMap[model]) {
        // Paso 4: Configuracion de Canales
        modelChannelMap[model].forEach((channel, index) => {
            steps.push({
                title: `${channel} Channel Setup`,
                content: (
                    <div>
                        {/* Inyector */}
                        <div className="form-floating form-floating-outline mb-4">
                            <select
                                id={`injector_${channel}`}
                                name={`injector_${channel}`}
                                className="form-select"
                                value={formData[`injector_${channel}`] || ""}
                                onChange={(e) =>
                                    handleChange({
                                        target: {
                                            name: `injector_${channel}`,
                                            value: e.target.value,
                                        },
                                    })
                                }
                                required
                            >
                                <option value="">Select an Option</option>
                                {channelOptions.injectors.map((injector) => (
                                    <option key={injector} value={injector}>
                                        {injector}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor={`injector_${channel}`}>Select the Injector:</label>
                        </div>

                        {/* Detector */}
                        <div className="form-floating form-floating-outline mb-4">
                            <select
                                id={`detector_${channel}`}
                                name={`detector_${channel}`}
                                className="form-select"
                                value={formData[`detector_${channel}`] || ""}
                                onChange={(e) =>
                                    handleChange({
                                        target: {
                                            name: `detector_${channel}`,
                                            value: e.target.value,
                                        },
                                    })
                                }
                                required
                            >
                                <option value="">Select an Option</option>
                                {channelOptions.detectors.map((detector) => (
                                    <option key={detector} value={detector}>
                                        {detector}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor={`detector_${channel}`}>Select the Detector:</label>
                        </div>

                        {/* Serial y descripción adicionales */}
                        {channelOptions.requiresSerialAndDescription.includes(formData[`detector_${channel}`]) && (
                            <>
                                <div className="form-floating form-floating-outline mb-4">
                                    <input
                                        type="text"
                                        id={`serial_${channel}`}
                                        name={`serial_${channel}`}
                                        className="form-control"
                                        value={formData[`serial_${channel}`] || ""}
                                        onChange={(e) =>
                                            handleChange({
                                                target: {
                                                    name: `serial_${channel}`,
                                                    value: e.target.value,
                                                },
                                            })
                                        }
                                        required
                                    />
                                    <label htmlFor={`serial_${channel}`}>Serial Number:</label>
                                </div>
                                <div className="form-floating form-floating-outline mb-4">
                                    <textarea
                                        id={`description_${channel}`}
                                        name={`description_${channel}`}
                                        className="form-control h-px-120"
                                        value={formData[`description_${channel}`] || ""}
                                        onChange={(e) =>
                                            handleChange({
                                                target: {
                                                    name: `description_${channel}`,
                                                    value: e.target.value,
                                                },
                                            })
                                        }
                                        required
                                    />
                                    <label htmlFor={`description_${channel}`}>Description:</label>
                                </div>
                            </>
                        )}
                        {/* Columnas */}
                        <div className="form-floating form-floating-outline mb-4">
                            <input
                                type="text"
                                id={`columnPart_${channel}`}
                                name={`columnPart_${channel}`}
                                className="form-control"
                                value={formData[`columnPart_${channel}`] || ""}
                                onChange={(e) =>
                                    handleChange({
                                        target: {
                                            name: `columnPart_${channel}`,
                                            value: e.target.value,
                                        },
                                    })
                                }
                                required
                            />
                            <label htmlFor={`columnPart_${channel}`}>Column Part Number:</label>
                        </div>
                        <div className="form-floating form-floating-outline">
                            <textarea
                                id={`columnDescription_${channel}`}
                                name={`columnDescription_${channel}`}
                                className="form-control h-px-120"
                                value={formData[`columnDescription_${channel}`] || ""}
                                onChange={(e) =>
                                    handleChange({
                                        target: {
                                            name: `columnDescription_${channel}`,
                                            value: e.target.value,
                                        },
                                    })
                                }
                                required
                            />
                            <label htmlFor={`columnDescription_${channel}`}>Description:</label>
                        </div>
                    </div>
                ),
            });
        });
    }

    // Paso 5: Configuracion del sampler
    if (model !== "LC6000") {
    // Paso 5: Configuración de Sampler 1
    steps.push({
        title: "Sampler Selection 1",
        content: (
            <div>
                {/* Configuración del Sampler 1 */}
                <div className="form-floating form-floating-outline mb-4">
                    <select
                        id="sampler1Type"
                        name="sampler1.type"
                        className="form-control"
                        value={formData.sampler1?.type || ""}
                        onChange={(e) =>
                            handleChange({
                                target: {
                                    name: "sampler1.type",
                                    value: e.target.value,
                                },
                            })
                        }
                        required
                    >
                        <option value="">Select a Sampler</option>
                        <option value="8400">8400</option>
                        <option value="8410">8410</option>
                        <option value="VERSA">VERSA</option>
                        <option value="HT3">HT3</option>
                        <option value="OTHER">OTHER</option>
                        <option value="NONE">NONE</option>
                    </select>
                    <label htmlFor="sampler1Type">Sampler 1</label>
                </div>

                {/* Campo adicional para OTHER */}
                {formData.sampler1?.type === "OTHER" && (
                    <div className="form-floating form-floating-outline mb-4">
                        <input
                            type="text"
                            id="sampler1Other"
                            name="sampler1.otherSampler"
                            className="form-control"
                            value={formData.sampler1?.otherSampler || ""}
                            onChange={(e) =>
                                handleChange({
                                    target: {
                                        name: "sampler1.otherSampler",
                                        value: e.target.value,
                                    },
                                })
                            }
                            required
                        />
                        <label htmlFor="sampler1Other">Indicate Sampler 1</label>
                    </div>
                )}

                <div className="form-floating form-floating-outline mb-4">
                    <input
                        type="text"
                        id="sampler1SerialNumber"
                        name="sampler1.serialNumber"
                        className="form-control"
                        value={formData.sampler1?.serialNumber || ""}
                        onChange={(e) =>
                            handleChange({
                                target: {
                                    name: "sampler1.serialNumber",
                                    value: e.target.value,
                                },
                            })
                        }
                        required
                    />
                    <label htmlFor="sampler1SerialNumber">Sampler 1 Serial Number</label>
                </div>

                <div className="form-floating form-floating-outline">
                    <textarea
                        id="sampler1Description"
                        name="sampler1.description"
                        className="form-control h-px-120"
                        value={formData.sampler1?.description || ""}
                        onChange={(e) =>
                            handleChange({
                                target: {
                                    name: "sampler1.description",
                                    value: e.target.value,
                                },
                            })
                        }
                        required
                    />
                    <label htmlFor="sampler1Description">Sampler 1 Description</label>
                </div>
            </div>
        ),
    });
    }

    if (model !== "LC6000") {
    // Paso 6: Configuración de Sampler 2
    steps.push({
        title: "Sampler Selection 2",
        content: (
            <div>
                {/* Similar configuración para Sampler 2 */}
                <div className="form-floating form-floating-outline mb-4">
                    <select
                        id="sampler2Type"
                        name="sampler2.type"
                        className="form-control"
                        value={formData.sampler2?.type || ""}
                        onChange={(e) =>
                            handleChange({
                                target: {
                                    name: "sampler2.type",
                                    value: e.target.value,
                                },
                            })
                        }
                    >
                        <option value="">Select a Sampler</option>
                        <option value="8400">8400</option>
                        <option value="8410">8410</option>
                        <option value="VERSA">VERSA</option>
                        <option value="HT3">HT3</option>
                        <option value="OTHER">OTHER</option>
                        <option value="NONE">NONE</option>
                    </select>
                    <label htmlFor="sampler2Type">Sampler 2</label>
                </div>

                {/* Campo adicional para OTHER */}
                {formData.sampler2?.type === "OTHER" && (
                    <div className="form-floating form-floating-outline mb-4">
                        <input
                            type="text"
                            id="sampler2Other"
                            name="sampler2.otherSampler"
                            className="form-control"
                            value={formData.sampler2?.otherSampler || ""}
                            onChange={(e) =>
                                handleChange({
                                    target: {
                                        name: "sampler2.otherSampler",
                                        value: e.target.value,
                                    },
                                })
                            }
                        />
                        <label htmlFor="sampler2Other">Indicate Sampler 2</label>
                    </div>
                )}

                <div className="form-floating form-floating-outline mb-4">
                    <input
                        type="text"
                        id="sampler2SerialNumber"
                        name="sampler2.serialNumber"
                        className="form-control"
                        value={formData.sampler2?.serialNumber || ""}
                        onChange={(e) =>
                            handleChange({
                                target: {
                                    name: "sampler2.serialNumber",
                                    value: e.target.value,
                                },
                            })
                        }
                    />
                    <label htmlFor="sampler2SerialNumber">Sampler 2 Serial Number</label>
                </div>

                <div className="form-floating form-floating-outline">
                    <textarea
                        id="sampler2Description"
                        name="sampler2.description"
                        className="form-control h-px-120"
                        value={formData.sampler2?.description || ""}
                        onChange={(e) =>
                            handleChange({
                                target: {
                                    name: "sampler2.description",
                                    value: e.target.value,
                                },
                            })
                        }
                    />
                    <label htmlFor="sampler2Description">Sampler 2 Description</label>
                </div>
            </div>
        ),
    });
    }  

    // Paso 6: Información adicional
    if (model !== "LC6000") {
    steps.push({
        title: "Additional Info",
        content: (
            <div>
                {/* Fecha del servicio */}
                <div className="form-floating form-floating-outline mb-4">
                    <input
                        type="date"
                        id="serviceDate"
                        name="serviceDate"
                        className="form-control"
                        value={formData.serviceDate || new Date().toISOString().split('T')[0]} // Fecha actual
                        onChange={(e) =>
                            handleChange({
                                target: {
                                    name: "serviceDate",
                                    value: e.target.value,
                                },
                            })
                        }
                        disabled // Campo deshabilitado
                    />
                    <label htmlFor="serviceDate">Day of Service</label>
                </div>

                {/* Tipo de Servicio */}
                <div className="form-group mb-4">
                    <label className="mb-2">Type of Service:</label>
                    <div>
                        {[
                            "Install",
                            "PM – Preventive Maintenance",
                            "Repair",
                            "IQ – Installation Qualification",
                            "OQ – Operational Qualification",
                        ].map((type) => (
                            <div key={type} className="form-check">
                                <input
                                    type="checkbox"
                                    id={`serviceType_${type}`}
                                    name="serviceType"
                                    value={type}
                                    className="form-check-input"
                                    checked={
                                        formData.serviceType?.split(", ")
                                            .filter(Boolean)
                                            .some((selected) => selected === type) || false
                                    }
                                    onChange={(e) => {
                                        const isChecked = e.target.checked;
                                        const value = e.target.value;
                                        const existingValues =
                                            formData.serviceType?.split(", ").filter(Boolean) || [];

                                        const updatedValues = isChecked
                                            ? [...existingValues, value]
                                            : existingValues.filter((v) => v !== value);

                                        handleChange({
                                            target: {
                                                name: "serviceType",
                                                value: updatedValues.join(", "),
                                            },
                                        });
                                    }}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor={`serviceType_${type}`}
                                >
                                    {type}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>


                {/* Descripción General */}
                <div className="form-floating form-floating-outline">
                    <textarea
                        id="generalDescription"
                        name="generalDescription"
                        className="form-control h-px-120"
                        value={formData.generalDescription || ""}
                        onChange={(e) =>
                            handleChange({
                                target: {
                                    name: "generalDescription",
                                    value: e.target.value,
                                },
                            })
                        }
                        
                    />
                    <label htmlFor="generalDescription">
                    Overall Description
                    </label>
                </div>
            </div>
        ),
    });
    }  

    // Paso 7: Confirmación de Información Adicional
    if (model !== "LC6000") {
    steps.push({
        title: "Setup Overview",
        content: (
            <div className="summary-content">
                {/* Imagen */}
                <div className="h-100">
                    <div className="h-100 text-center">
                        {preview ? (
                            <img
                                src={preview}
                                alt="Vista previa"
                                className="card-img-top mb-3"
                                style={{ maxHeight: "200px", objectFit: "contain" }}
                            />
                        ) : (
                            <p>No image uploaded.</p>
                        )}
                    </div>
                    {/* Botón Confirmar y Enviar */}
                    <div className="text-center mb-4">
                        <button
                            style={{ textTransform: "none" }}
                            className="btn btn-primary btn-next waves-effect waves-light"
                            onClick={handleFinish}
                        >
                            Confirm & Send
                        </button>
                    </div>
    
                    {/* Información General */}
                    <div className="card-body">
                        <div className="row gx-0">
                            <div className="d-flex justify-content-between align-items-center w-100">
                                <p className="card-text mb-1">
                                    <strong>S/N:</strong>{" "}
                                    {formData.serialNumber || "Unspecified"}
                                </p>
                                <p className="card-text mb-1">
                                    <strong>Brand:</strong>{" "}
                                    {formData.brand || "Unspecified"}
                                </p>
                                <p className="card-text mb-1">
                                    <strong>Model:</strong>{" "}
                                    {formData.model || "Unspecified"}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
    
                {/* Canales */}
                {modelChannelMap[formData.model] &&
                    modelChannelMap[formData.model].length > 0 && (
                        <div className="card-body pb-0">
                            {modelChannelMap[formData.model].map(
                                (channelName, index) => (
                                    <div key={index} className="summary-channel">
                                        <div className="card-subtitle mb-3">
                                            <strong style={{ color: "#043268" }}>
                                                Channel: {channelName}
                                            </strong>
                                        </div>
                                        <p className="card-text">
                                            <strong>Inyector:</strong>{" "}
                                            {formData[`injector_${channelName}`] ||
                                                "Unspecified"}
                                        </p>
                                        <p className="card-text">
                                            <strong>Detector:</strong>{" "}
                                            {formData[`detector_${channelName}`] ||
                                                "Unspecified"}{" "}
                                            {[
                                                "SCD",
                                                "SQ",
                                                "TQ",
                                            ].includes(
                                                formData[
                                                    `detector_${channelName}`
                                                ]
                                            ) && (
                                                <>
                                                    <strong>S/N:</strong>{" "}
                                                    {formData[
                                                        `serial_${channelName}`
                                                    ] || "Unspecified"}
                                                </>
                                            )}
                                        </p>
                                        <div className="card-subtitle mb-3">
                                            <strong>Columns</strong>
                                        </div>
                                        <p>
                                            <strong>Part Number:</strong>{" "}
                                            {formData[`columnPart_${channelName}`] ||
                                                "Unspecified"}
                                        </p>
                                        <p>
                                            <strong>Description:</strong>{" "}
                                            {formData[
                                                `columnDescription_${channelName}`
                                            ] || "Unspecified"}
                                        </p>
                                        <hr className="container-m-nx mb-4" />
                                    </div>
                                )
                            )}
                        </div>
                    )}
    
                {/* Sampler */}
                {/* Sampler */}
{formData.samplers?.length > 0 && (
    <div className="card-body pt-0">
        <div className="card-subtitle mb-3">
            <strong style={{ color: "#043268" }}>Samplers</strong>
        </div>
        {formData.samplers.map((sampler, index) => (
            <div key={index} className="mb-4">
                <p className="card-text">
                    <strong>Sampler {index + 1} - Type:</strong>{" "}
                    {sampler.type || "Unspecified"}
                </p>
                {sampler.type === "OTHER" && (
                    <p className="card-text">
                        <strong>Sampler {index + 1} - Name:</strong>{" "}
                        {sampler.otherSampler || "Unspecified"}
                    </p>
                )}
                <p className="card-text">
                    <strong>Sampler {index + 1} - S/N:</strong>{" "}
                    {sampler.serialNumber || "Unspecified"}
                </p>
                <p className="card-text">
                    <strong>Sampler {index + 1} - Description:</strong>{" "}
                    {sampler.description || "Unspecified"}
                </p>
                {index < formData.samplers.length - 1 && (
                    <hr className="container-m-nx" />
                )}
            </div>
        ))}
    </div>
)}

    
                {/* Información Adicional */}
                <div className="card-body pt-0">
                    <div className="card-subtitle mb-3">
                        <strong style={{ color: "#043268" }}>
                        Additional Info
                        </strong>
                    </div>
                    <p>
                        <strong>Date of Service:</strong>{" "}
                        {formData.serviceDate || "Unspecified"}
                    </p>
                    <p>
                        <strong>Type of Service:</strong>{" "}
                        {formData.serviceType || "Unspecified"}
                    </p>
                    <p>
                        <strong>Overall Description:</strong>{" "}
                        {formData.generalDescription || "Unspecified"}
                    </p>
                    <hr className="container-m-nx mb-4" />
                </div>
            </div>
        ),
    });
    }  
    

    

    return steps;
};



