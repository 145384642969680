import React, { useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Header, Footer } from './Widgets';
import { UserContext } from '../context/UserContext';
import GeneralSteps from '../components/GeneralSteps';
import Swal from 'sweetalert2';
import Webcam from 'react-webcam';
import StepForm from '../components/StepForm';

export function Report() {
    const { sku } = useParams();
    const [message, setMessage] = useState('');
    const [report, setReport] = useState(null);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [showWebcam, setShowWebcam] = useState(false);
    const webcamRef = useRef(null);
    const [menuCollapsed, setMenuCollapsed] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { setUserInfo, userInfo } = useContext(UserContext);
    const [modules, setModules] = useState([]);
    const [channels, setChannels] = useState([]); // Lista de canales
    const [sampler, setSampler] = useState(null); // Información del sampler

    

    useEffect(() => {
        fetchReportData();
    }, [sku]);

    const fetchReportData = () => {
        axios
            .get(`https://web-production-a6f8.up.railway.app/report/${sku}`, {
                withCredentials: false,
            })
            .then((res) => {
                if (res.data.Status === 'Exito') {
                    const { Report, Modules, Channels, Samplers } = res.data;
    
                    // Establecer el estado con los datos recibidos
                    setReport(Report);
    
                    if (Report.brand === 'Scion Instruments' && Report.model === 'LC6000') {
                        // Configurar módulos solo para LC6000
                        console.log('Módulos recibidos:', Modules);
                        setModules(Modules || []);
                    } else {
                        // Configurar canales y sampler para otros modelos
                        console.log('Canales recibidos:', Channels);
                        console.log('Sampler recibido:', Samplers);
                        setChannels(Channels || []);
                        setSampler(Samplers || []); 
                    }
    
                    // Configurar la imagen de vista previa si existe
                    if (Report.image_name) {
                        setPreview(`https://storage.googleapis.com/app-techcomp.appspot.com/${Report.image_name}`);
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Folio no encontrado',
                        text: 'Este modelo aún no se encuentra en nuestra base de datos',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    }).then(() => {
                        navigate('/report');
                    });
                }
            })
            .catch((err) => {
                console.error('Error al obtener el reporte:', err);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Hubo un problema al cargar el reporte.',
                });
            });
    };
    

    const handleCapture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        const imageFile = dataURLtoFile(imageSrc, 'captured_image.jpg');
        setFile(imageFile);
        setPreview(imageSrc);
        setShowWebcam(false);
    };

    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const handleLogout = () => {
        axios.get('https://web-production-a6f8.up.railway.app/logout')
        .then(res => {
            window.location.reload(true);
        }).catch(err => console.log(err));
    };


    const handleRemoveImage = () => {
        setFile(null);
        setPreview(null);
    };

    useEffect(() => {
        const htmlElement = document.documentElement;
        if (menuCollapsed) {
            htmlElement.classList.add('layout-menu-collapsed');
        } else {
            htmlElement.classList.remove('layout-menu-collapsed');
        }
    }, [menuCollapsed]);

    const handleToggleMenu = () => {
        setMenuCollapsed(!menuCollapsed);
        const htmlElement = document.documentElement;
        const layoutMenu = document.getElementById('layout-menu');
        if (!menuCollapsed) {
            layoutMenu.classList.add('slide-in');
            layoutMenu.classList.remove('slide-out');
            htmlElement.classList.add('light-style', 'layout-navbar-fixed', 'layout-compact', 'layout-menu-fixed', 'layout-menu-expanded');
        } else {
            layoutMenu.classList.add('slide-out');
            layoutMenu.classList.remove('slide-in');
            htmlElement.classList.remove('light-style', 'layout-navbar-fixed', 'layout-compact', 'layout-menu-fixed', 'layout-menu-expanded');
        }
    };

    const handleNavigateToLogin = () => {
        navigate('/login', { state: { from: location } });
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
        const day = String(date.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
                    <div className="app-brand demo" style={{ cursor: 'pointer' }}>
                        <a href="#" className="app-brand-link">
                            <span className="app-brand-logo demo">
                                <img src="/assets/img/favicon/favicon-techcomp.png" alt="techcomp" style={{ width: '25px' }} />
                            </span>
                            <span className="app-brand-text demo menu-text fw-semibold ms-2">Techcomp</span>
                        </a>
                    </div>

                    <div className="menu-inner-shadow"></div>

                    <ul className="menu-inner py-1">
                        <li className="menu-item active open">
                            <a href='/' className="menu-link menu-toggle">
                                <i className="menu-icon tf-icons ri-home-smile-line"></i>
                                <div data-i18n="Dashboards">Dashboard</div>
                            </a>
                        </li>
                        <li className="menu-item active open">
                            <a href='/report' className="menu-link menu-toggle">
                                <i className="menu-icon ri-file-search-line"></i>
                                <div data-i18n="Dashboards">Reportes</div>
                            </a>
                        </li>
                    </ul>
                </aside>
                <div className="layout-page">
                    <Header toggleMenu={handleToggleMenu}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row g-6">
                                <div className="col-12 col-xxl-12">
                                <div className="card h-100">
                                    <div className="card-header pt-0">
                                        <h5
                                            className="text-muted text-center"
                                            style={{fontWeight: "500" }}
                                        >
                                        </h5>
                                    </div>
                                    <div className="card-body col-md">
                                        {report?.image_uploaded === 0 && (
                                            <GeneralSteps
                                                sku={sku}
                                                userInfo={report.user_id} // Pasar el ID del usuario si es necesario
                                                onSubmit={(finalJson) => {
                                                    // Lógica después de enviar el formulario
                                                    console.log("JSON Enviado desde Report:", finalJson);
                                                    Swal.fire({
                                                        icon: "success",
                                                        title: "Reporte enviado",
                                                        text: "El reporte se ha enviado correctamente.",
                                                    }).then(() => {
                                                        fetchReportData(); // Recargar datos del reporte
                                                    });
                                                }}
                                            />
                                        )}
                                        {/* Mostrar resumen si image_uploaded es 1 */}
                                        {report?.image_uploaded === 1 && (
                                            <div className="summary-content">
                                                {/* Imagen */}
                                                <div className="h-100">
                                                    {preview ? (
                                                        <img
                                                            src={preview}
                                                            alt="Vista previa"
                                                            className="card-img-top"
                                                        />
                                                    ) : (
                                                        <p>No se ha cargado ninguna imagen.</p>
                                                    )}
                                                    <div className="card-body">
                                                        <div className="row gx-0">
                                                            <div className="d-flex justify-content-between align-items-center w-100">
                                                                <p className="card-text mb-0">
                                                                    <strong>S/N:</strong> {report.serialNumber || "No especificado"}
                                                                </p>
                                                                <p className="card-text mb-0">
                                                                    <strong>Brand:</strong> {report.brand || "No especificado"}
                                                                </p>
                                                                <p className="card-text mb-0">
                                                                    <strong>Model:</strong> {report.model || "No especificado"}
                                                                </p>
                                                            </div>
                                                            <br/>
                                                            <p className="card-text mt-5">
                                                                    <strong>Engineer Name:</strong> {report.engineer_name	 || "No especificado"}
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>

                                                {/* Mostrar información dependiendo del modelo */}
                                                {report.brand === "Scion Instruments" &&
                                                    report.model === "LC6000" ? (
                                                        // Mostrar módulos para LC6000
                                                        <div className="card-body pb-0 pt-0">
                                                            <hr className="container-m-nx mb-4" />
                                                            <div className="card-subtitle mb-3">
                                                                <strong style={{ color: '#043268' }}>Modules</strong>
                                                            </div>
                                                            <div className="mt-3">
                                                                {modules
                                                                    .reduce((acc, module) => {
                                                                        if (module.module_name === "Detector") {
                                                                            // Agrupar detectores en un solo array
                                                                            acc.detectores.push(module);
                                                                        } else {
                                                                            // Otros módulos se agregan normalmente
                                                                            acc.otros.push(module);
                                                                        }
                                                                        return acc;
                                                                    }, { otros: [], detectores: [] })
                                                                    .otros.map((module, index) => (
                                                                        <div key={index} className="mb-3">
                                                                            <strong>{module.module_name}:</strong>
                                                                            <p className="ms-3">
                                                                                {module.module_value || "No especificado"}
                                                                                {module.module_serial_number && (
                                                                                    <span>
                                                                                        <br />
                                                                                        <strong>Serial Number:</strong> {module.module_serial_number}
                                                                                    </span>
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    ))}

                                                                {/* Detectores */}
                                                                {modules.reduce((acc, module) => {
                                                                    if (module.module_name === "Detector") {
                                                                        acc.push(module);
                                                                    }
                                                                    return acc;
                                                                }, []).length > 0 && (
                                                                    <div className="mb-3">
                                                                        <strong>Detector:</strong>
                                                                        <ul className="ms-3">
                                                                            {modules
                                                                                .filter((module) => module.module_name === "Detector")
                                                                                .map((detector, index) => (
                                                                                    <li key={index}>
                                                                                        {detector.module_value || "No especificado"}
                                                                                        {detector.module_serial_number && (
                                                                                            <span>
                                                                                                <br />
                                                                                                <strong>Serial Number:</strong> {detector.module_serial_number}
                                                                                            </span>
                                                                                        )}
                                                                                    </li>
                                                                                ))}
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <hr className="container-m-nx mb-4" />
                                                        </div>
                                                    ) : (
                                                    // Mostrar canales y sampler para otros modelos
                                                    <>
                                                        {/* Canales */}
                                                        {channels.length > 0 && (
                                                            <div className="card-body pb-0">
                                                                {channels.map((channel, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className="summary-channel"
                                                                    >
                                                                        <div className="card-subtitle mb-3">
                                                                            <strong style={{color:'#043268'}}>Channel {channel.channel_title || "No especificado"}</strong>
                                                                        </div>
                                                                        <p className="card-text">
                                                                            <strong>Inyector:</strong>{" "}
                                                                            {channel.injector ||
                                                                                "No especificado"}
                                                                        </p>
                                                                        <p>
                                                                            <strong>Detector:</strong>{" "}
                                                                            {channel.detector || "No especificado"}
                                                                            {" "}   
                                                                            {["SCD", "SQ", "TQ"].includes(
                                                                            channel.detector
                                                                        ) && (
                                                                            <>
                                                                                <strong>Detector S/N:</strong>{" "}
                                                                                {channel.detector_serial_number ||
                                                                                    "No especificado"}
                                                                            </>
                                                                        )}
                                                                        </p>
                                                                        <div className="card-subtitle mb-3">
                                                                            <strong>Columns</strong>
                                                                        </div>
                                                                        <p>
                                                                            <strong>Part Number:</strong>{" "}
                                                                            {channel.column_pn ||
                                                                                "No especificado"}
                                                                        </p>
                                                                        <p>
                                                                            <strong>Description:</strong>{" "}
                                                                            {channel.column_description ||
                                                                                "No especificado"}
                                                                        </p>
                                                                        <hr className="container-m-nx mb-4" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}

                                                        {/* Sampler */}
                                                        {Array.isArray(sampler) && sampler.length > 0 && (
                                                            <div className="card-body pt-0">
                                                                <div className="card-subtitle mb-3">
                                                                    <strong style={{ color: '#043268' }}>Samplers</strong>
                                                                </div>
                                                                {sampler.map((samplerItem, index) => (
                                                                    <div key={index} className="mb-4">
                                                                        <p className="card-text">
                                                                            <strong>Sampler {index + 1} - Type:</strong>{" "}
                                                                            {samplerItem.sampler_type || "No especificado"}
                                                                        </p>
                                                                        {samplerItem.sampler_type === "OTHER" && (
                                                                            <p className="card-text">
                                                                                <strong>Sampler {index + 1} - Name:</strong>{" "}
                                                                                {samplerItem.sampler_name || "No especificado"}
                                                                            </p>
                                                                        )}
                                                                        <p className="card-text">
                                                                            <strong>Sampler {index + 1} - Serial Number:</strong>{" "}
                                                                            {samplerItem.sampler_serial_number || "No especificado"}
                                                                        </p>
                                                                        <p className="card-text">
                                                                            <strong>Sampler {index + 1} - Description:</strong>{" "}
                                                                            {samplerItem.sampler_description || "No especificado"}
                                                                        </p>
                                                                        {index < sampler.length - 1 && <hr className="container-m-nx" />}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}


                                                    </>
                                                )}

                                                {/* Información Adicional */}
                                                <div className="card-body pt-0">
                                                    <div className="card-subtitle mb-3">
                                                        <strong style={{color:'#043268'}}>Additional Information</strong>
                                                    </div>
                                                    <p>
                                                        <strong>Date of Service:</strong>{" "}
                                                        {report.service_date
                                                            ? formatDate(report.service_date)
                                                            : "No especificado"}
                                                    </p>
                                                    <p>
                                                        <strong>Type of Service:</strong>{" "}
                                                        {report.service_type || "No especificado"}
                                                    </p>
                                                    <p>
                                                        <strong>General Description:</strong>{" "}
                                                        {report.general_description || "No especificado"}
                                                    </p>
                                                    <hr className="container-m-nx mb-4" />
                                                </div>

                                                {/* Botón para descargar la imagen */}
                                                {report.image_name && (
                                                    <div className="text-center">
                                                        <button
                                                            className="btn btn-primary btn-next waves-effect waves-light"
                                                            onClick={() => {
                                                                const link = document.createElement("a");
                                                                link.href = `https://storage.googleapis.com/app-techcomp.appspot.com/${report.image_name}`;
                                                                link.download = report.image_name; // Nombre del archivo que quieres dar
                                                                link.click();
                                                            }}
                                                        >
                                                            Download image
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="card-header text-center pt-0">
                                        QR: {sku}
                                    </div>

                                    
                                </div>

                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}



export function ReportDefault() {
    const [auth, setAuth] = useState(false);
    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [menuCollapsed, setMenuCollapsed] = useState(false);
    const navigate = useNavigate();
    const { setUserInfo } = useContext(UserContext);

    axios.defaults.withCredentials = true;

    useEffect(() => {
        axios.get('https://web-production-a6f8.up.railway.app')
            .then(res => {
                if (res.data.Status === "Exito") {
                    setAuth(true);
                    setUserInfo({ name: res.data.name, type: res.data.type });
                    setName(res.data.name);
                } else {
                    setAuth(false);
                    setMessage(res.data.Error);
                    navigate('/login');
                }
            })
            .catch(err => {
                console.log(err);
                setMessage("Error al registrar");
            });
    }, [navigate]);

    const handleSearch = (e) => {
        e.preventDefault();
        axios.get(`https://web-production-a6f8.up.railway.app/report/${searchQuery}`)
            .then(res => {
                if (res.data.Status === 'Exito') {
                    navigate(`/report/${searchQuery}`);
                } else {
                    setMessage(res.data.Error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Este Folio no se encuentra en la base de datos',
                        customClass: {
                            confirmButton: 'btn btn-primary'
                        },
                        buttonsStyling: false
                    });
                }
            })
            .catch(err => {
                console.log(err);
                setMessage('Error al verificar Folio');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error al verificar el Folio Front',
                    customClass: {
                        confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                });
            });
    };

    const handleInputChange = (e) => {
        const value = e.target.value.toUpperCase();
        const regex = /^[A-Z0-9-]*$/;
        if (regex.test(value)) {
            setSearchQuery(value);
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Caracter inválido',
                text: 'Por favor, no ingreses caracteres especiales ni correos electrónicos.', 
                customClass: {
                    confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
            });
        }
    };

    useEffect(() => {
        const htmlElement = document.documentElement;
        if (menuCollapsed) {
            htmlElement.classList.add('layout-menu-collapsed');
        } else {
            htmlElement.classList.remove('layout-menu-collapsed');
        }
    }, [menuCollapsed]);

    const handleToggleMenu = () => {
        setMenuCollapsed(!menuCollapsed);
        const htmlElement = document.documentElement;
        const layoutMenu = document.getElementById('layout-menu');
        if (!menuCollapsed) {
            layoutMenu.classList.add('slide-in');
            layoutMenu.classList.remove('slide-out');
            htmlElement.classList.add('light-style', 'layout-navbar-fixed', 'layout-compact', 'layout-menu-fixed', 'layout-menu-expanded');
        } else {
            layoutMenu.classList.add('slide-out');
            layoutMenu.classList.remove('slide-in');
            htmlElement.classList.remove('light-style', 'layout-navbar-fixed', 'layout-compact', 'layout-menu-fixed', 'layout-menu-expanded');
        }
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
                    <div className="app-brand demo" style={{ cursor: 'pointer' }}>
                        <a href="#" className="app-brand-link">
                            <span className="app-brand-logo demo">
                                <img src="/assets/img/favicon/favicon-techcomp.png" alt="techcomp" style={{ width: '25px' }} />
                            </span>
                            <span className="app-brand-text demo menu-text fw-semibold ms-2">Techcomp</span>
                        </a>
                    </div>

                    <div className="menu-inner-shadow"></div>

                    <ul className="menu-inner py-1">
                        <li className="menu-item active open">
                            <a href='/' className="menu-link menu-toggle">
                                <i className="menu-icon tf-icons ri-home-smile-line"></i>
                                <div data-i18n="Dashboards">Dashboard</div>
                            </a>
                        </li>
                        <li className="menu-item active open">
                            <a href='/report' className="menu-link menu-toggle">
                                <i className="menu-icon ri-file-search-line"></i>
                                <div data-i18n="Dashboards">Reportes</div>
                            </a>
                        </li>
                    </ul>
                </aside>
                <div className="layout-page">
                    <Header toggleMenu={handleToggleMenu}/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row g-6">
                                <div className="col-12 col-xxl-12">
                                    <div className="card h-100">
                                        <div className="card-header">
                                            <h5 className="mb-0 text-center">Check Reports</h5>
                                        </div>
                                        <div className="card-body pt-4">
                                            <form className="d-flex" onSubmit={handleSearch}>
                                                <div className="input-group input-group-sm">
                                                    <span className="input-group-text"><i className="tf-icons ri-search-line"></i></span>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        value={searchQuery}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </form>
                                            <br/>
                                            <p className="text-center">
                                            Here you can check reports. Please, select a specific page to check the report details.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}



export default Report;
